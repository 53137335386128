.book-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}

.summary {
  font-weight: bold;
}

.important {
  background-color: antiquewhite;
}
.original-text {
  display: none;
  font-style: italic;
  margin-top: 10px;
}

.show-original .original-text {
  display: block;
}

.read-button {
  background-color: #ff7f7f;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 5px;
}
